import React from "react";
import Helmet from "react-helmet";
import Layout from "../layout";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import WorkTogether from "../components/WorkTogether/WorkTogether";
import PageHeader from "../components/PageHeader/PageHeader";
import Services from "../components/Services/Services";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";

class ServicesPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title={`Services | ${config.siteTitle}`} />
        <SEO />
        <Header />
        <PageHeader
          title="Our Services"
          content="We build awesome stuff for awesome people. And we only win when our clients win so we are full committed to making that happen. The must cutting-edge practices and technologies plus a unique team result in so many services where engineering and design meet scale to the next level."
        />
        <Services icons />
        <WorkTogether />
        <Footer />
      </Layout>
    );
  }
}

export default ServicesPage;
